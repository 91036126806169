import queryString from 'query-string'
import { CANONICAL_URL, CONFIG, CONTACT_US_URL } from 'shared/constants'

// Helpers to build absolute URLs
export const REGISTRY_SUBDOMAIN_PREFIX = 'my'

let _host = ''

export const setHost = (host) => {
  _host = host
}

const origin = () => {
  const hostname = typeof window !== 'undefined' ? window.location.host : _host

  let newHost

  // Staging and canary subdomains are prefixed with 'my-'
  if (hostname.startsWith(`${REGISTRY_SUBDOMAIN_PREFIX}-`)) {
    newHost = hostname.replace(`${REGISTRY_SUBDOMAIN_PREFIX}-`, '')
  } else if (hostname.startsWith(`${REGISTRY_SUBDOMAIN_PREFIX}.`)) {
    newHost = hostname.replace(`${REGISTRY_SUBDOMAIN_PREFIX}.`, 'www.')
  }

  return `https://${newHost || hostname}`
}

export const absoluteUrl = (path) => {
  const prefixes = ['http', '//', '#']
  if (prefixes.some((prefix) => path.startsWith(prefix))) {
    return path
  }
  return `${origin()}${path}`
}

// Matchers
export const helloBabyMatcher =
  /https?:\/\/(?:\w*\.?babyli\.st|\w*\.?babylist\.com|babylist\.test|localhost:\d{4})\/hello-baby/

export const isHelloBabyUrl = (url) => helloBabyMatcher.test(url)

// URLs
export const babylistUrl = (path) => `${CANONICAL_URL}${path}`
export const babylistHealth = 'https://www.babylist.com/health'
export const androidAppUrl =
  'https://play.google.com/store/apps/details?id=st.babyli.babylist'
export const cloudinaryUrl =
  'https://api.cloudinary.com/v1_1/babylist/image/upload'
export const expectfulUrl = 'https://expectful.com'
export const facebookUrl = 'https://www.facebook.com/babylist'
export const instagramUrl = 'https://www.instagram.com/babylist/'
export const linkedInUrl = 'https://www.linkedin.com/company/babylist'
export const tiktokUrl = 'https://www.tiktok.com/@babylist'
export const iOSAppUrl =
  'https://itunes.apple.com/us/app/babylist-baby-registry/id718582092?mt=8'
export const pinterestUrl = 'https://www.pinterest.com/babylist/'
export const twitterUrl = 'https://twitter.com/babylist'
export const youtubeUrl =
  'https://www.youtube.com/channel/UCuKydP0MCUhj2n04_A4c6ig'
export const youtubeChromeTutorialUrl =
  'https://www.youtube.com/watch?v=6UT_pCMCL6w'
export const youtubeFirefoxTutorialUrl =
  'https://www.youtube.com/watch?v=LEVorILFguc'
export const youtubeInternetExplorerTutorialUrl =
  'https://www.youtube.com/watch?v=WfdEYwmn7-c'
export const youtubeSafariTutorialUrl =
  'https://www.youtube.com/watch?v=KY8vxlspJwo'

// Paths
export const expectfulPostPath = (slug) =>
  slug ? `${expectfulUrl}/articles/${slug}` : expectfulUrl
export const aboutPath = '/about'
export const aboutCareersPath = '/about/careers'
export const aboutImpactPath = '/about/impact'
export const aboutLeadershipPath = '/about/leadership'
export const aboutPressPath = '/about/press'
export const babylistBabesPath = (dateString) => `/babylist-babes/${dateString}`

export const adminRegistryPath = (registryId) => `/admin/registry/${registryId}`
export const adminDeleteChecklistItemsPath = (registryId) =>
  `/admin/registry/${registryId}/delete_checklist_items`
export const addItemsPath = '/add-items'
export const addButtonPath = '/registry/new/add-button'
export const apiV2RegistryCategoriesPath = (registryId, categoryId) =>
  `/api/v2/registry/${registryId}/categories/${categoryId}`
export const apiV3AboutCompanyStatsPath = '/api/v3/company_stats'
export const apiV3AboutFeaturedNewsPath = '/api/v3/about_featured_news'
export const apiV3AboutCareersPath = '/api/v3/about_careers'
export const apiV3AboutLeadersPath = '/api/v3/about_leadership'
export const apiV3AboutPressUpdatesPath = '/api/v3/about_press_updates'
export const apiV3AboutHABPath = '/api/v3/about_health_advisory_board_content'
export const apiV3AddressesPath = '/api/v3/address'
export const apiV3GiftGiverAddressesPath = '/api/v3/address/gift_giver'
export const apiV3AmazonCartItemsPath = (id) =>
  `/api/v3/amazon_cart_items/${id || ''}`
export const apiV3FeedPath = '/api/v3/feed'
export const apiV3FeedItemDismissalsPath = '/api/v3/feed_item_dismissals'
export const apiV2Images = '/api/v2/images'
export const apiV3AsyncJobsPath = (id) => `/api/v3/async_jobs/${id}`
export const apiV3BabyRegistryChecklistCategoriesPath =
  '/api/v3/baby_registry_checklist_categories'
export const apiV3BLLaunchDarklyFlagPath = '/api/v3/bl_launch_darkly/flag'
export const apiV3BLLaunchdDarklyMetricPath = '/api/v3/bl_launch_darkly/metric'
export const apiV3BrandsPath = (brandId) => `/api/v3/brands/${brandId}`
export const apiV3BrandProductsPath = (brandId) =>
  `/api/v3/brand/${brandId}/products`
export const apiV3BrandsSearchPath = (searchTerm) =>
  `/api/v3/brands?search_term=${searchTerm}`
export const apiV3CartPath = '/api/v3/cart'
export const apiV3CartConvertToRegistryItemsPath =
  '/api/v3/cart/convert_to_registry_items'
export const apiV3CartItemsPath = '/api/v3/cart_items'
export const apiV3CartItemPath = (id) => `${apiV3CartItemsPath}/${id}`
export const apiV3CashFundPreferencesPath = `/api/v3/cash_fund_preferences`
export const apiV3CatalogCategoriesPath = '/api/v3/catalog_categories'
export const apiV3BookmarkletPath = '/api/v3/bookmarklet'
export const apiV3AddItemsGuidesPath = '/api/v3/add_items_guides'
export const apiV3CatalogCategoriesFacetsPath = (catalogCategoryId) =>
  `/api/v3/catalog_categories/${catalogCategoryId}/facets`
/**
 * @param {number|string} catalogCategoryId - The id or slug of a category (leaf category).
 * @returns {string} API path for fetching products related to a catalog category.
 */
export const apiV3CatalogCategoriesProductsPath = (catalogCategoryId) =>
  `/api/v3/catalog_categories/${catalogCategoryId}/products`
export const apiV3CheckoutPath = '/api/v3/checkouts'
export const apiV3ContentCollectionsPath = (slug) =>
  `/api/v3/content_collections/${slug}`
export const apiV3CreateGiftReturnsPath = '/api/v3/gift_returns'
export const apiV3CreateInaccuracyReportPath = '/api/v3/inaccuracy_reports'
export const apiV3CreateRegistryPath = '/api/v3/registries'
export const apiV3CreateGiftGiverReminderPath = (registryId) =>
  `${apiV3CreateRegistryPath}/${registryId}/reminders`
export const apiV3GroupGiftRedemptionsPath =
  '/api/v3/group_gift_fund_redemptions'
export const apiV3GuidesConfigPath = '/api/v3/guides_config'
export const apiV3InboxPath = '/api/v3/message_inbox'
export const apiV3MessagesPath = (messageId) => `/api/v3/message/${messageId}`
export const apiV3RegistryPath = (idOrSlug) => `/api/v3/registries/${idOrSlug}`
export const apiV3LinkExternalRegistryPath = (registryId) =>
  `/api/v3/registries/${registryId}/external_registries/link`
export const apiV3TransferExternalRegistryPath = (registryId) =>
  `/api/v3/registries/${registryId}/external_registries/transfer`
export const apiV3ExternalRegistrySupportTicketPath = (registryId) =>
  `/api/v3/registries/${registryId}/external_registries/support_ticket`
export const apiV3TemplatesPath = (templateId) =>
  `/api/v3/templates/${templateId || ''}`
export const apiV3TemplatesClonePath = (templateId) =>
  `${apiV3TemplatesPath(templateId)}/clone`
export const apiV3OrderPath = (uuid) => `/api/v3/orders/${uuid}`
export const apiV3OrderAddressPath = '/api/v3/order_address'
export const apiV3PasswordVerificationPath = '/api/v3/password_verifications'
export const apiV3PostsPath = '/api/v3/posts'
export const apiV3PostsByNumberOfWeeksPregnantPath = `${apiV3PostsPath}/by-number-of-weeks-pregnant`
export const apiV3PostSignupEcommercePath = '/api/v3/post_signup_ecommerce'
export const apiV3ProductsPath = '/api/v3/products'
export const apiV3ProductPath = (id) => `${apiV3ProductsPath}/${id}`
export const apiV3ProductAvailabilitySubscriptionsPath =
  '/api/v3/product_availability_subscriptions'
export const apiV3PdpPath = (id) => `${apiV3ProductPath(id)}?is_pdp=true`
export const apiV3ProductsSearchPath = (term) =>
  `/api/v3/products?search_term=${term}`
export const apiV3ProductsCategoryPath = (categoryId) =>
  `${apiV3ProductsPath}?category_ids[]=${categoryId}`
export const apiV3RegistriesPath = '/api/v3/registries'
export const apiV3RegistriesChecklistCategoriesPath = (
  registryId,
  categoryId
) =>
  `${apiV3RegistryPath(registryId)}/checklist_categories${
    categoryId ? `/${categoryId}` : ''
  }`
export const apiV3RegistriesShopPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/shop`
export const apiV3RegItemsPath = (idOrSlug) =>
  `${apiV3RegistryPath(idOrSlug)}/reg_items`
export const apiV3RegItemPath = (regItemId, registryId, guestView = false) =>
  `${apiV3RegItemsPath(registryId)}/${regItemId}${guestView ? '?view=guest' : ''}`
export const apiV3RegItemReservationsPath = (regItemId) =>
  `/api/v3/reg_items/${regItemId}/reservations`
export const apiV3RegItemGroupGiftsPath = (registryId, regItemId) =>
  `${apiV3RegItemPath(regItemId, registryId)}/group_gift`

export const apiV3RegistryVisitorReservationsPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/visitor_reservations`

export const apiV3ReservedRegItemsPath = (registryId, isGuestView = false) =>
  `${apiV3RegistryPath(registryId)}/reservations${
    isGuestView ? '?view=guest' : ''
  }`
export const apiV3RegistrySortFilterOptionsPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/filter_sort_options`
export const apiV3MinimalRegItemsPath = (registryId) =>
  `${apiV3RegItemsPath(registryId)}/minimal`
export const apiV3RegistryCategoriesPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/categories`
export const apiV3RegistryCategoryPath = (registryId, categoryId) =>
  `${apiV3RegistryCategoriesPath(registryId)}/${categoryId}`
export const apiV3RegistryGiftGiversPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/gift_givers`

export const apiV3RegistrySetupGoalsPath = (registryId) =>
  `${apiV3RegistryPath(registryId)}/registry_setup_goals`

export const apiV3RegistryInsertCardOrdersPath = (registryId) =>
  `${apiV3RegistriesPath}/${registryId}/insert_card_orders`
export const apiV3RegistryInsertCardOrderPath = (
  registryId,
  insertCardOrderId
) => `${apiV3RegistryInsertCardOrdersPath(registryId)}/${insertCardOrderId}`
export const apiV3CashFundContributionsPath = '/api/v3/cash_fund_contributions'
export const apiV3CashFundContributionPath = (token) =>
  `${apiV3CashFundContributionsPath}/${token}`
export const apiV3ConfirmCashFundContributionsPath = (token) =>
  `${apiV3CashFundContributionPath(token)}/confirm`
export const apiV3CancelCashFundContributionsPath = (token) =>
  `${apiV3CashFundContributionPath(token)}/cancel`
export const apiV3ReservationsPath = '/api/v3/reservations'
export const apiV3ReservationPath = (token) =>
  `${apiV3ReservationsPath}/${token}`
export const apiV3ReservationRecoveryPath = (regItemId) =>
  `${apiV3ReservationsPath}/${regItemId}/recover`
export const apiV3RestrictedEmailPreferencesPath =
  '/api/v3/restricted/email_preferences'
export const apiV3RestrictedGiftGiverPreferencesPath =
  '/api/v3/restricted/gift_giver_preferences'
export const apiV3RestrictedThankYouNoteAllowedPath =
  '/api/v3/restricted/thank_you_note_allowed'
export const apiV3RestrictedFamiliesPath = '/api/v3/restricted/families'
export const apiV3PurchaseReturnsPath = '/api/v3/purchase_returns'
export const apiV3ShareRegistryPath = (registryId) =>
  `${apiV3RegistriesPath}/${registryId}/share`
export const apiV3ShipmentsPath = '/api/v3/shipments'
export const apiV3ShipmentPath = (shipmentId) =>
  `${apiV3ShipmentsPath}/${shipmentId}`
export const apiV3ShipNowPath = (shipmentId) =>
  `${apiV3ShipmentsPath}/${shipmentId}/ship_now`
export const apiV3UnifiedSearchPath = (query, eventLocation) =>
  `/api/v3/search?q=${query}&event_location=${eventLocation}`
export const apiV3UserCreditPath = '/api/v3/user_credit'
export const apiV3UserEventsPath = `/api/v3/user/events`
export const apiV3UserOrdersPath = `/api/v3/orders`
export const apiV3UserPath = '/api/v3/user'
export const apiV3UserSessionPath = '/api/v3/user_session'
export const apiV3TwoFactorsPath = '/api/v3/two_factors'
export const apiV3ZipLookupsPath = '/api/v3/zip_lookups'
export const apiV3RevealReservationPath = '/api/v3/reservations/reveal'
export const apiV3OnboardingStoresPath = '/api/v3/onboarding_stores'
export const bestBabyProductsPath = '/best-baby-products'
export const blAdminGenericProductPath = (id) => `/bl_admin/products/${id}`
export const blAdminProductPath = (genericProductId, id) =>
  `${blAdminGenericProductPath(genericProductId)}/${id}`
export const blAdminSingleProductPath = (id) =>
  `/bl_admin/products/single/${id}`
export const blAdminApiCacheRefreshesPath = '/bl_admin/api/cache_refreshes'
export const blAdminApiGiftCardPath = (id) => `/bl_admin/api/gift_cards/${id}`
export const blAdminApiGiftCardExchangesPath =
  '/bl_admin/api/gift_card_exchanges'
export const blAdminApiReservationCancelPath =
  '/bl_admin/api/cancel_reservation'
export const blAdminApiReservationRemindersPath =
  '/bl_admin/api/reservation_reminders'
export const blAdminApiReservationUnpurchasePath =
  '/bl_admin/api/unpurchase_reservation'
export const blAdminApiUserCreditsPath = '/bl_admin/api/user_credits'
export const blAdminApiUserCreditRedemptionsPath =
  '/bl_admin/api/user_credit_redemptions'
export const blAdminApiUserReviewsPath = '/bl_admin/api/user_reviews'
export const blAdminOrderPath = (idOrReference) =>
  `/bl_admin/orders/${idOrReference}`
export const cashFundFAQPath =
  '//help.babylist.com/hc/en-us/sections/203997608-Cash-Funds'
export const cashFundChangesFAQPath =
  '//help.babylist.com/hc/en-us/articles/360043140374'
export const cashFundAutoTransferExplanationPath =
  '//help.babylist.com/hc/en-us/articles/218112297'
export const cashFundPendingExplanationPath =
  '//help.babylist.com/hc/en-us/articles/218112327'
export const cashFundPrintablePath =
  '//images.babylist.com/image/upload/v1724869350/PDFs/Cash_Fund_-_Printable.pdf'
export const catalogPath = '/catalog'
export const catalogCategoryPath = (categorySlug) =>
  `${catalogPath}/${categorySlug}`
export const catalogSearchPath = '/catalog/search'
export const catalogSearchResultsPath = (q) =>
  `${catalogSearchPath}?search_term=${q}`
export const checklistPath = '/baby-registry-checklist'
export const checklistPdfPath = '/baby-registry-checklist.pdf'
export const checklistQuizPath = '/baby-registry-checklist-quiz'
export const couponsPath = '/coupons'
export const checklistEmailPath = `${checklistQuizPath}/email`
export const checklistQuestionsPath = (questionId) =>
  `${checklistQuizPath}/q/${questionId}`
export const checklistStartPath = '/baby-registry-checklist/start_checklist'
export const checkoutPath = '/store/checkout'
export const checkoutWithCartPath = `${checkoutPath}?cart_type=store`
export const checkoutWithHelloBabyBoxPath = (registryId) =>
  `/store-checkout-hbb?registry_id=${registryId}`
export const checkoutWithReservationsPath = (reservationTokens) =>
  `/store/checkout/reservations?tokens[]=${reservationTokens.join(
    '&tokens[]='
  )}`
export const contactUsPath = CONTACT_US_URL
export const cookiesPath = '/cookie-notice'
export const ccpaPath = '/ccpa'
export const seoSitemapPath = '/sitemap'
export const userReviewsPath = '/user-reviews'
export const createProductReviewPath = (userId, gpId) =>
  `${userReviewsPath}/${userId}/${gpId}`
export const diaperCalculatorPath = '/diaper-calculator'
export const editorialPolicyPath = '/lp/editorial-policies-guidelines'
export const faqPath = '//help.babylist.com/hc/en-us'
export const giftGiverFaqPath =
  '//help.babylist.com/hc/en-us/categories/115001505227-I-m-Giving-a-Gift'
export const registryHomePath = `/registry/home`
export const registryAddItemsPath = `/registry/add-items`
export const feedGuideViewerPath = (url) =>
  `${registryHomePath}/view-guide?url=${url}`
export const findPath = '/find'
export const findResultsPath = (term) => `${findPath}?search_term=${term}`
export const freeBabyStuffGuide =
  '//babylist.com/hello-baby/free-baby-stuff-while-pregnant'
export const freeShippingFaqPath =
  '//help.babylist.com/hc/en-us/articles/217912848'
export const shippingConsolidationHelpPath =
  '//help.babylist.com/hc/en-us/articles/11452933105051-Registry-Shipping-Consolidation'
export const whereIsMyOrderPath =
  '//help.babylist.com/hc/en-us/articles/1260805696869-Where-is-my-Babylist-order'
export const freightAndDeliveryHelpPath =
  '//help.babylist.com/hc/en-us/articles/26648623457819-Freight-Orders-and-Deliveries'
export const genericProductsPath = '/gp'
export const giftCardPDPPath = '/gp/babylist-store-gift-card/12881/19106'
export const landingPagePath = (slug) => `/lp/${slug}`
export const giftTrackerPath = (registryId) =>
  `/registry/${registryId ? `${registryId}/` : ''}gift-tracker`
export const groupGiftFaqPath =
  'https://help.babylist.com/hc/en-us/sections/360009409273-Group-Gifts'
export const dueDateCalculatorAcqexPath = '/due-date-calculator-acqex'
export const dueDateCalculatorPath = '/due-date-calculator'
export const dueDateCalculatorDatePath = (slug) =>
  slug ? `${dueDateCalculatorPath}/${slug}` : dueDateCalculatorPath
export const dueDateCalculatorDateURLFromDate = (date) =>
  babylistUrl(
    `${dueDateCalculatorPath}/${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`
  )
export const healthPath = '/health'
export const healthPartsPath = '/health/parts'
export const helloBabyBoxFaq =
  '//help.babylist.com/hc/en-us/sections/115004098188'
export const helloBabyBoxHowToGet =
  '//help.babylist.com/hc/en-us/articles/360054057353'
export const helloBabyBoxOOS =
  '//help.babylist.com/hc/en-us/articles/360057483513'
export const helloBabyPath = '/hello-baby'
export const guidesPath = '/hello-baby'
export const helloBabyAuthors = (slug) => `${helloBabyPath}/authors/${slug}`
export const helloBabySearchPath = (q) =>
  `${helloBabyPath}/search?search_term=${q}`
export const helloBabyPostPath = (slug) =>
  slug ? `${helloBabyPath}/${slug}` : helloBabyPath
export const helloBabyTopicPath = (slug) =>
  slug ? `${helloBabyPath}/${slug}` : helloBabyPath

export const helloBabyBoxPostPath = helloBabyPostPath('box')
export const howToCreateABabyRegistryPath = helloBabyPostPath(
  'how-to-create-a-baby-registry'
)
export const howToShareABabyRegistryPath = helloBabyPostPath(
  'how-to-share-your-baby-registry'
)
export const helloBabyEarlySignsOfPregnancyPath = helloBabyPostPath(
  'early-pregnancy-symptoms'
)
export const helloBabyPregnancyWeekByWeekPath = helloBabyPostPath(
  'pregnancy-week-by-week'
)
export const helloBabyRegistryMustHavesPath = helloBabyPostPath(
  'baby-registry-must-haves'
)
export const helloBabyProductsAndGearPath = helloBabyPostPath('gear')
export const helloBabyNamesPath = helloBabyPostPath('baby-names')
export const helloBabyParentingPath = helloBabyPostPath('parenting')
export const helloBabyGiftGuidesPath = helloBabyPostPath('gift-guides')
export const helloBabyBestStrollersPath = helloBabyPostPath('best-strollers')
export const helloBabyBestHighChairsPath = helloBabyPostPath('best-high-chairs')
export const helloBabyBestBassinetsPath = helloBabyPostPath('best-bassinets')
export const helloBabyHospitalBagPath = helloBabyPostPath(
  'what-to-pack-in-your-hospital-bag'
)
export const helloBabyHowManyDiapersPath = helloBabyPostPath(
  'how-many-diapers-babys-first-year'
)
export const helloBabySampleRegistriesPath = '/hello-baby/sample-registries'
export const helpAndFavorsPath = '/store/help-and-favors'
export const cashFundsPath = '/store/cash-funds'
export const giftCardsPath = '/store/gift-cards'
export const ourStoryPath = '/our-story'
export const locationsPath = '/locations'
export const healthAdvisoryBoardPath = '/health-advisory-board'
export const indexPath = '/index'
export const listsPath = '/lists'
export const loginPath = '/login'
export const loginPathWithRedirect = (redirectPath) =>
  `${origin()}${loginPath}?redirect_path=${redirectPath}`
export const loginPathAsPopup = '/login?popup=true'
export const logoutPath = '/logout'
export const forgotPasswordPath = '/forgot_password'
export const passwordResetsPath = '/password_resets'
export const mobileAppDownloadPath = (path = '', desktop = '') =>
  `/mobile-app-download?path=${path}&desktop=${desktop}`
export const newRegistryPath = '/registry/new'
export const newRegistryPathFromChecklist = '/registry/new?lp_content=checklist'
export const createAnotherRegistryPath = '/registry/build/road-ahead'
export const createBirthdayWishlistPath = '/registry/build/birthday'
export const createHolidayWishlistPath = '/registry/build/holiday'
export const orderPath = (uuid) => `/orders/${uuid}`
export const saveRegistryItems = '/registry/save_item'
export const onboardingPath = (step) => `${newRegistryPath}/${step}`
export const registryPath = (registrySlug, queryParams) =>
  `/list/${registrySlug}${
    queryParams && Object.keys(queryParams).length
      ? `?${queryString.stringify(queryParams)}`
      : ''
  }`
export const registrySubdomainPath = (registrySlug) => `/${registrySlug}`
export const organizeRegistryPath = (registrySlug) =>
  `${registryPath(registrySlug)}/organize`

export const pdpPath = (slug, genericProductId, productId) => {
  if (!slug || !genericProductId) {
    throw new Error('pdpPath requires a slug and genericProductId')
  }
  const path = `${genericProductsPath}/${slug}/${genericProductId}`
  return productId ? `${path}/${productId}` : path
}
export const placementsDecisionPath = '/placements/api/decision'
export const pregnancyEmailPath = '/pregnancy-emails'
export const privacyPolicyPath = '/privacy'
export const consumerHealthDataPrivacyPath = '/consumer-health-data-privacy'
export const productRegistrationPath = '/product-registration'
export const productReviewPolicyPath = '/lp/product-review-processes'
export const quickBuyPath = '/quick_buy'
export const recoverReservationFaqPath =
  '//help.babylist.com/hc/en-us/articles/213339437'
export const registryChecklistPath = '/registry/checklist'
export const registryDiscountPath = '/registry-discount'
export const registryExternalPath = '/registry/external'
export const registryViewAsGuestPath = (slug) =>
  registryPath(slug, { view: 'guest' })
export const registryViewAsGuestPathFromId = (registryId) =>
  `/reg/${registryId}?view=guest`
export const registryFromIdPath = (registryId) => `/reg/${registryId}`
export const registryFilterByRegistryDiscount = (slug) =>
  slug
    ? `/${slug}?filter=${CONFIG.store.registryDiscountFilter}`
    : `?filter=${CONFIG.store.registryDiscountFilter}&view=guest`
export const registryShopPath = `/registry/shop`
export const registryGiftTrackerPath = `/registry/gift-tracker`

export const reservationMarkAsPurchasedEmailActionPath = (token) =>
  `/email_actions/reservation_purchase/${token}?purchased=1`
export const reservationsPath = '/reservations'
export const reservationPath = (idOrToken) => `${reservationsPath}/${idOrToken}`
export const reservationPurchasedPath = (idOrToken, showModal = false) =>
  `${reservationPath(idOrToken)}${
    showModal ? '?show_purchase_confirmation_modal=true' : ''
  }`

export const furnitureShippingSurchargeGuidePath =
  '//help.babylist.com/hc/en-us/articles/5381426515099-How-much-is-the-furniture-shipping-surcharge'
export const returnsPath = '/returns'
export const returnGiftPath = `${returnsPath}/gift`
export const returnPurchasePath = `${returnsPath}/purchase`
export const returnPolicyPath =
  '//help.babylist.com/hc/en-us/articles/217912798-What-s-your-return-policy-'
export const loopGiftPath = '//babylist-store.loopreturns.com/#/gift'
export const reviewAnswersPath = '/review_answers'
export const reviewsPath = '/user_reviews'

export const editArrivalDateHelpPath =
  '//help.babylist.com/hc/en-us/articles/115008854368'

export const settingsPath = '/settings'
export const settingsAccountInfoPath = `${settingsPath}/account`
export const settingsBirthdayPreferencesPath = `${settingsPath}/birthday-preferences`
export const settingsCashFundPreferencesPath = `${settingsPath}/cash-fund-preferences`
export const settingsEmailPreferencesPath = `${settingsPath}/email-preferences`
export const settingsGiftCardsAndPromotionsPath = `${settingsPath}/gift-cards-and-promos`
export const settingsYourOrdersPath = `${settingsPath}/your-orders`
export const settingsRegistryPath = `${settingsPath}/registry`
export const settingsRegistryVisibilityPath = `${settingsRegistryPath}/visibility`
export const settingsShippingAddressPath = `${settingsPath}/shipping`
export const settingsConsolidatedShippingPath = `${settingsPath}/simple-shipping`
export const settingsRegistryCollaboratorsPath = `${settingsPath}/collaborators`
export const shipmentViewPath = `/shipments`
export const shipmentDetailViewPath = (shipmentId) =>
  `${shipmentViewPath}/${shipmentId}`

export const shareExtensionPath = '/share-extension'
export const shareExtensionHowItWorksPath = `${shareExtensionPath}/how-it-works`

export const shareRegistryPath = '/registry/share'
export const shareRegistryInsertCardsPath = `${shareRegistryPath}/insert-cards`
export const shareRegistryLinkPath = `${shareRegistryPath}/link`
export const shopRegistryPath = '/registry/shop'
export const shopSmallPath = '/shop-small'
export const showroomPath = '/lp/showroom'
export const storePath = '/store'
export const storeSalePath = '/store/sale'
export const storeBrandPagePath = (slug, id) => `${storePath}/b/${slug}/${id}`
export const storeCategoryPath = (categorySlug) =>
  `${storePath}/${categorySlug}`
export const storeFsaHsaCategoryPath = `${storePath}/fsa-hsa-eligible`
export const storeComingSoonPath = `${storePath}/coming-soon`
export const storeSearchPath = '/store/search'
export const storeNewPath = `${storePath}/new`
export const storeSearchResultsPath = (q) =>
  `${storeSearchPath}?search_term=${q}`
export const unifiedSearchStoreSearchResultsPath = (q) =>
  `${storeSearchResultsPath(q)}&unified_search=true`
export const storeShortCodePath = (shortCode) =>
  `${storePath}/code/${shortCode}`
export const guidesSearchResultsPath = (q, currenPath) =>
  `${currenPath}#stq=${q}&stp=1`
export const termsOfUsePath = '/terms'
export const unableToAddRegItemPath = '/unable_to_add_reg_item'
export const unreserveReservationPath = (token) =>
  `/reservation/unreserve/${token}`
export const whyBabyListPath = '/why-babylist'
export const babylossPath = '/miscarriage-loss'
export const babylossGuidesPath = '/miscarriage-loss/guides'
export const manageCookiesPath = '/manage-cookies'
export const wildCardCouponPath = '/gp/blank-coupon/1002/1806'
